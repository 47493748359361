@use "../../partials/_colors" as *;
@use "../../partials/_typography" as *;
@use "../../partials/_variables" as *;
@use "../../partials/_mixins" as *;

*,
*::before,
*::after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.image__style{
    height: 71vh;
    width: 100vw;
    background-size: cover;
    
    @include tablet{
        height: 71vh;
        width: 90%;
        background-size: cover;
        margin-top: 1rem;
    }
}

.each-slide{
    margin: 0rem;
    @include tablet{
    display: flex;
    justify-content: space-between;
  
    }
}

.tools-container__style{
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;
    align-items: center;
    width: 100%;
}

.tools-image__style{
    height: 2rem;
    width: 2rem;
    background-color: white;
}

.tools__style{
    padding: 0.5rem;
    color: $white;
}

.site-desc__style{
    color: $white;
}

.desc__style{  
    width: 85%;
    margin: 0 auto;
    margin-top: 1rem;
    border: 2px solid black;
    background-color: $raisin-black;
    padding: 1rem;
    padding: 1rem 2rem;
}

.link__style{
    text-decoration: none;
    color: $white;
}

.click__style{
    padding: 1rem 0;
    font-weight: bold;
    font-size: 1.5rem;
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }

.trivia__style{
    padding-top: 5rem;
    font-size: 2rem;
    width: 100%;
    height: 100%;
    background-color: $secondary-color-slate;
    color: $white;
}

