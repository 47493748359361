@use "../../partials/_colors" as *;
@use "../../partials/_typography" as *;
@use "../../partials/_variables" as *;
@use "../../partials/_mixins" as *;


*,
*::before,
*::after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.page-header{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}

.page-header__wrapper a:hover {
    background-color: $raisin-black;
    border-radius: 5px;
    padding: 4px;
    color: $white;
}

.header-container{
    font-family: $primary-font;
    padding: 1rem 0;
}

.nav-list__item{
    display: none;
    @include tablet{
        display: initial;
        text-decoration: none;
        margin-left:3rem;
        color: $raisin-black
    }
}


.menu-list__item{
    text-decoration: none;
    color: $white;
    @include tablet{
        display: none;
    }
}

.dropbtn {
    background-color: $white;
    border-radius: 5px;
    color: $raisin-black;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}
  
.dropdown {
    position: relative;
    display: inline-block;
    z-index: 1000;
    @include tablet{
        display: none;
    }
}
  
.dropdown-content {
    display: none;
    position: absolute;
    background-color: $raisin-black;
    padding: 4px;
    border-radius: 5px;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
  

.dropdown-content a {
    color: $white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-button__style{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
  
.dropdown-content a:hover {
    background-color: $white;
    color: $raisin-black;
    padding: 4px;
    border-radius: 5px;
}
  
.dropdown:hover .dropdown-content {
    display: block;
}
  

.dropdown:hover .dropbtn {
    background-color: $raisin-black;
    border-radius: 5px;
    padding: 4px;
    color: $white;
}