@use "../../partials/_colors" as *;
@use "../../partials/_typography" as *;

*,
*::before,
*::after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.flip-box__style{
  background-color: transparent;
  width: 100%;
  height: 400px;
//   border: 1px solid #f1f1f1;
  perspective: 1000px; 
}

.about-desc__style{
    width: 80%;
    margin: 2rem auto;
}

.dev-image__style{
    width: 9rem;
    height: 8rem;
}

.cont-desc__style{
    margin: 1rem 0;
}

.flip-box__inner{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;   
}

.flip-box__style:hover .flip-box__inner {
    transform: rotateY(180deg);
}

.about-container__style{
    display:flex;
}

.front-view__style, .back-view__style, .front-end__style, .back-end__style {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.front-view__style {
    background-color: #bbb;
    color: black;
}

.back-profile__style, .profile__style{
    height: 60vh;
    width: 40vw;
    border-radius: 10px;
}

.back-view__style {
    background-color: dodgerblue;
    color: red;
    transform: rotateY(180deg);
}

// .desc__style{
//     margin-left: 25rem;
// }

.front-end__style{
    border-radius: 10px;
    color: $raisin-black;
    background-color: $white;
 
}

.back-end__style{
    transform: rotateY(180deg);
    border-radius: 10px;
    color: $white;
    background-color: $raisin-black;
}

.frontend-tools__style, .backend-tools__style{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    flex-flow:wrap;
}

.frontend-title__style, .backend-title__style{
    margin: 2rem 0;
    padding-bottom: 2rem;
}