//primary colors
$raisin-black: #1E1E24;
$dark-lava: #493B2A;

//secondary color
$secondary-color-slate:#5C667E;
$secondary-color-cloud:#BDC5D5;


//background color
$background-color-ivory:#FBFFF1;
$cultured: #F5F5F5;


//supporting background color
$support-color-background-green:rgba(21, 132, 99, 0.07);
$support-color-background-red:rgba(201, 69, 21, 0.07);

//white
$white:#FFFFFF;
