@use "../../partials/_colors" as *;
@use "../../partials/_typography" as *;

*,
*::before,
*::after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.icon__style{
    height: 2rem;
    width: 2.5rem;
    margin: 0 1rem;
}

.footer__link{
    text-decoration: none;
    color: $raisin-black;
}

.footer-content__style{
    display:flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    // flex-direction: row;
    color: $raisin-black
}

.footer-container{
    font-family: $primary-font;
    // background-color: $columbia-blue;
    display: flex;
    padding: 1rem 0;
    margin-top: 8rem;
}
