@use './colors' as *;
@use './variables' as *;
@use './typography' as *;

//spacing mixins

@mixin tablet {
    @media (min-width:$tablet-size) {
        @content;
    }
}

@mixin desktop {
    @media (min-width:$desktop-size) {
        @content;
    }
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
    @content;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
    @content;
}
