@use "../../partials/_colors" as *;
@use "../../partials/_typography" as *;

*,
*::before,
*::after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.header__style, .header-desc__style{
    margin-left: 10rem;
    padding-top: 1.5rem;
}
.home-desc__style{
    background: url(../../assets/bridge.jpg) center no-repeat;
    background-size: cover;
    height: 80vh;
}

.new-desc__style{
    // background: url(../../assets/trees.jpeg) center no-repeat;
    background: url(../../assets/flower-pic.jpeg) center no-repeat;
    background-size: cover;
    height: 80vh;
}
.third-desc__style{
    background: url(../../assets/car.jpeg) center no-repeat;
    background-size: cover;
    height: 80vh;
}